import {Link} from 'gatsby'
import React from 'react'
import BlockText from './block-text'
import Img from 'gatsby-image'

import styles from './project-preview.module.css'

function ProjectPreview (props) {
  const color = props.themeColor.hex ? props.themeColor.hex : ''

  return (
    <Link className={styles.root} to={`/project/${props.slug.current}`}>
      <div className={styles.hero} style={{background: color}}>
        <Img
          className={styles.image}
          fluid={props.mainImage.asset.fluid}
          alt={props.mainImage.alt}
        />
        <div className={styles.content}>
          <h3 className={styles.title}>{props.title}</h3>
          {props._rawExcerpt && (
            <div className={styles.excerpt}>
              <BlockText blocks={props._rawExcerpt} />
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}

export default ProjectPreview
