import React from 'react'
import BlockContent from './block-content'
import Container from './container'
import ProjectPreview from './project-preview'
import Img from 'gatsby-image'

import styles from './project.module.css'

function Project (props) {
  const {_rawBody, title, relatedProjects} = props
  const color = props.themeColor.hex ? props.themeColor.hex : ''

  return (
    <article className={styles.root}>
      {props.mainImage && props.mainImage.asset && (
        <div className={styles.hero} style={{background: color}}>
          <Img
            className={styles.image}
            fluid={props.mainImage.asset.fluid}
            alt={props.mainImage.alt}
          />
        </div>
      )}
      <Container>
        <div className={styles.mainContent}>
          <h1 className={styles.title}>{title}</h1>
          {_rawBody && <BlockContent className={styles.innerContent} blocks={_rawBody || []} />}
        </div>
      </Container>

      {relatedProjects && relatedProjects.length > 0 && (
        <aside className={styles.preFooter}>
          <div className={styles.relatedProjects}>
            <ul>
              {relatedProjects.map(project => (
                <li key={`related_${project.id}`}>
                  <ProjectPreview {...project} />
                </li>
              ))}
            </ul>
          </div>
        </aside>
      )}
    </article>
  )
}

export default Project
